import type { ElementType } from 'react';

import type { TeaserGridExtensions } from './teaser-grid-extensions';
import type { TTeaserGridItem } from './teaser-grid-item';

export type GAd = TTeaserGridItem<
  'ad',
  {
    adFormat: string;
    adSlot: string;
    adSlotPrefix?: string;
    isSticky: boolean;
  }
>;

export const createGAdExtension = (adElement?: ElementType): TeaserGridExtensions =>
  adElement
    ? {
        ad: (item: GAd, i: number) => ({
          Component: adElement,
          key: `ad_${item.data.adSlot}_${i}`,
          componentProps: {
            adFormat: item.data.adFormat,
            adSlot: item.data.adSlot,
            adSlotPrefix: item.data.adSlotPrefix,
            isSticky: item.data.isSticky,
            adStickyTop: 'var(--i-teaser-grid-row-gap)',
          },
        }),
      }
    : {};
